var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        {
          attrs: { disabled: _vm.disabled },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
            reset: _vm.onReset
          }
        },
        [
          _c("div", { staticClass: "filters" }, [
            _c(
              "div",
              { staticClass: "filters--item filters--item__title" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Title:")
                ]),
                _c("StringFilter", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "Search by title (min 3 chars)"
                  },
                  model: {
                    value: _vm.currentFilters.title,
                    callback: function($$v) {
                      _vm.$set(_vm.currentFilters, "title", $$v)
                    },
                    expression: "currentFilters.title"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__maps" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Maps:")
                ]),
                _c("MapFilter", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.currentFilters.map_ids,
                    callback: function($$v) {
                      _vm.$set(_vm.currentFilters, "map_ids", $$v)
                    },
                    expression: "currentFilters.map_ids"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__agents" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Team 1 Agents:")
                ]),
                _c("AgentFilter", {
                  attrs: {
                    disabled: _vm.disabled,
                    option: _vm.currentFilters.agent_option
                  },
                  on: {
                    "update:option": function($event) {
                      return _vm.$set(
                        _vm.currentFilters,
                        "agent_option",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.currentFilters.agent_ids,
                    callback: function($$v) {
                      _vm.$set(_vm.currentFilters, "agent_ids", $$v)
                    },
                    expression: "currentFilters.agent_ids"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__agents" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Team 2 Agents:")
                ]),
                _c("AgentFilter", {
                  attrs: {
                    disabled: _vm.disabled,
                    option: _vm.currentFilters.agent_vs_option
                  },
                  on: {
                    "update:option": function($event) {
                      return _vm.$set(
                        _vm.currentFilters,
                        "agent_vs_option",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.currentFilters.agent_vs_ids,
                    callback: function($$v) {
                      _vm.$set(_vm.currentFilters, "agent_vs_ids", $$v)
                    },
                    expression: "currentFilters.agent_vs_ids"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__period" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Period:")
                ]),
                _c("PeriodFilter", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.filter_period,
                    callback: function($$v) {
                      _vm.filter_period = $$v
                    },
                    expression: "filter_period"
                  }
                })
              ],
              1
            ),
            false
              ? _c(
                  "div",
                  { staticClass: "filters--item filters--item__composition" },
                  [
                    _c("div", { staticClass: "filters--item--title" }, [
                      _vm._v("Team 1 Compositions:")
                    ]),
                    _c("AgentCompositionFilter", {
                      attrs: {
                        "agents-fallback": false,
                        compositions: _vm.compositions,
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.currentFilters.agent_comps,
                        callback: function($$v) {
                          _vm.$set(_vm.currentFilters, "agent_comps", $$v)
                        },
                        expression: "currentFilters.agent_comps"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            false
              ? _c(
                  "div",
                  { staticClass: "filters--item filters--item__composition" },
                  [
                    _c("div", { staticClass: "filters--item--title" }, [
                      _vm._v("Team 2 Compositions:")
                    ]),
                    _c("AgentCompositionFilter", {
                      attrs: {
                        "agents-fallback": false,
                        compositions: _vm.compositions,
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.currentFilters.agent_vs_comps,
                        callback: function($$v) {
                          _vm.$set(_vm.currentFilters, "agent_vs_comps", $$v)
                        },
                        expression: "currentFilters.agent_vs_comps"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12" } }, [
                _c(
                  "div",
                  { staticClass: "btn-actions" },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "button",
                          {
                            ref: "searchBtn",
                            staticClass: "btn btn-warning",
                            class: { highlight: _vm.changed },
                            attrs: { type: "submit", disabled: _vm.disabled }
                          },
                          [_vm._v(" Search ")]
                        ),
                        _vm.$refs.searchBtn
                          ? _c(
                              "BTooltip",
                              {
                                attrs: {
                                  show: _vm.changed,
                                  target: _vm.$refs.searchBtn,
                                  triggers: "manual"
                                }
                              },
                              [_vm._v(" Apply new filters ")]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "reset",
                              variant: "outline-warning",
                              disabled: _vm.disabled
                            }
                          },
                          [_vm._v(" Reset")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }