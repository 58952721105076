var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FilterSelection", { attrs: { items: [] } }, [
    _vm.inputType === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue"
            }
          ],
          staticClass: "form-control",
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.internalValue)
              ? _vm._i(_vm.internalValue, null) > -1
              : _vm.internalValue
          },
          on: {
            change: function($event) {
              var $$a = _vm.internalValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.internalValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.internalValue = $$c
              }
            }
          }
        })
      : _vm.inputType === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue"
            }
          ],
          staticClass: "form-control",
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            type: "radio"
          },
          domProps: { checked: _vm._q(_vm.internalValue, null) },
          on: {
            change: function($event) {
              _vm.internalValue = null
            }
          }
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue"
            }
          ],
          staticClass: "form-control",
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            type: _vm.inputType
          },
          domProps: { value: _vm.internalValue },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.internalValue = $event.target.value
            }
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }