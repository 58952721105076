<template>
  <FilterSelection :items="[]">
    <input
      class="form-control"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="inputType"
      v-model="internalValue"
    />
  </FilterSelection>
</template>

<script>
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'

export default {
  name: 'StringFilter',
  components: {
    FilterSelection,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: Boolean,
    inputType: {
      type: String,
      default: 'search',
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    internalValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        return this.$emit('update', value)
      },
    },
  },
}
</script>

<style scoped></style>
