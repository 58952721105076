// import stringCompare from '@/utils/stringCompare'

import axios from '../axios'

import { getEvent } from './events'

// export const getScrimCompositions = async ({ map_id } = {}, { cancelToken } = {}) => {
//   const response = await axios.get(`/val/v2/recent/scr-matches/agent-comps`, { cancelToken, params: { map_id } })
//   return response.data
// }

export const getScrimCompositions = async () => {
  return []
}

export const getSearches = async ({ cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/recent/matches/searches`, { cancelToken })
  return response.data
}

export const getRecentSearchedEvents = async ({ cancelToken } = {}) => {
  const recentSearches = await getSearches({ cancelToken })
  const searchedEvents = recentSearches
    .flatMap(search => {
      const last = new Date(search.updated).getTime()
      const weight = search.count / Math.ceil((new Date().getTime() - last) / (1000 * 60 * 60 * 24))
      return search.body.event_ids?.flatMap(event_id => ({
        event_id,
        weight,
      }))
    })
    .filter(({ event_id }) => event_id != null)
  const sortedByUsage = [...searchedEvents].sort((a, b) => a.weight - b.weight)
  const recentEventIds = {}
  sortedByUsage.every(({ event_id }) => {
    if (!(event_id in recentEventIds)) {
      recentEventIds[event_id] = true
    }
    return Object.keys(recentEventIds).length < 20
  })
  const events = await Promise.all(Object.keys(recentEventIds).map(event_id => getEvent({ event_id }, { cancelToken })))
  // events.sort(stringCompare({ picker: event => event.name }))
  return Object.freeze(events)
}
