<template>
  <div class="mt-2">
    <b-form :disabled="disabled" @submit.prevent="onSubmit" @reset="onReset">
      <div class="filters">
        <div class="filters--item filters--item__title">
          <div class="filters--item--title">Title:</div>
          <StringFilter
            :disabled="disabled"
            placeholder="Search by title (min 3 chars)"
            v-model="currentFilters.title"
          />
        </div>
        <div class="filters--item filters--item__maps">
          <div class="filters--item--title">Maps:</div>
          <MapFilter :disabled="disabled" v-model="currentFilters.map_ids" />
        </div>
        <div class="filters--item filters--item__agents">
          <div class="filters--item--title">Team 1 Agents:</div>
          <AgentFilter
            :disabled="disabled"
            :option.sync="currentFilters.agent_option"
            v-model="currentFilters.agent_ids"
          />
        </div>
        <div class="filters--item filters--item__agents">
          <div class="filters--item--title">Team 2 Agents:</div>
          <AgentFilter
            :disabled="disabled"
            :option.sync="currentFilters.agent_vs_option"
            v-model="currentFilters.agent_vs_ids"
          />
        </div>
        <div class="filters--item filters--item__period">
          <div class="filters--item--title">Period:</div>
          <PeriodFilter :disabled="disabled" v-model="filter_period" />
        </div>
        <div v-if="false" class="filters--item filters--item__composition">
          <div class="filters--item--title">Team 1 Compositions:</div>
          <AgentCompositionFilter
            :agents-fallback="false"
            :compositions="compositions"
            :disabled="disabled"
            v-model="currentFilters.agent_comps"
          />
        </div>
        <div v-if="false" class="filters--item filters--item__composition">
          <div class="filters--item--title">Team 2 Compositions:</div>
          <AgentCompositionFilter
            :agents-fallback="false"
            :compositions="compositions"
            :disabled="disabled"
            v-model="currentFilters.agent_vs_comps"
          />
        </div>
      </div>

      <b-row>
        <!-- Search Button -->
        <b-col sm="12">
          <div class="btn-actions">
            <b-form-group>
              <button
                ref="searchBtn"
                type="submit"
                class="btn btn-warning"
                :class="{ highlight: changed }"
                :disabled="disabled"
              >
                Search
              </button>

              <BTooltip v-if="$refs.searchBtn" :show="changed" :target="$refs.searchBtn" triggers="manual">
                Apply new filters
              </BTooltip>

              <b-button type="reset" variant="outline-warning" :disabled="disabled"> Reset</b-button>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import { getScrimCompositions } from '@/api/recent'
import axios from '@/axios'
import { pxNullable } from '@/components/Map/types'
import AgentCompositionFilter from '@/components/Search/filters/AgentCompositionFilter.vue'
import AgentFilter from '@/components/Search/filters/AgentFilter.vue'
import MapFilter from '@/components/Search/filters/MapFilter.vue'
import PeriodFilter from '@/components/Search/filters/PeriodFilter.vue'
import StringFilter from '@/components/Search/filters/StringFilter.vue'
import { pxOption } from '@/types'
import deepEqual from '@/utils/deepEqual'

export default {
  name: 'ScrimSearchFilters',
  model: {
    prop: 'value',
    event: 'update',
  },

  components: { AgentCompositionFilter, PeriodFilter, AgentFilter, MapFilter, StringFilter },

  props: {
    disabled: px.bool.def(false),
    value: px.shape({
      title: px.string,
      map_ids: px.arrayOf(px.string),
      agent_ids: px.arrayOf(px.string).def([]),
      agent_option: pxOption(),
      agent_vs_ids: px.arrayOf(px.string).def([]),
      agent_vs_option: pxOption(),
      begin_time: pxNullable(px.string),
      end_time: pxNullable(px.string),
    }),
  },

  data() {
    return {
      compositions: [],

      currentFilters: {
        ...this.value,
      },

      savedSearch: false,
    }
  },

  computed: {
    changed() {
      return !deepEqual(this.currentFilters, this.value)
    },

    filter_period: {
      get() {
        return {
          begin_time: this.currentFilters.begin_time,
          end_time: this.currentFilters.end_time,
        }
      },
      set({ begin_time, end_time }) {
        this.$set(this.currentFilters, 'begin_time', begin_time)
        this.$set(this.currentFilters, 'end_time', end_time)
      },
    },

    mapIds() {
      return this.currentFilters.map_ids
    },
  },

  watch: {
    mapIds: {
      immediate: true,
      handler() {
        this.fetchCompositions()
      },
    },
    value: {
      immediate: true,
      handler(val) {
        this.currentFilters = {
          ...val,
        }
      },
    },
  },

  beforeDestroy() {
    this.cancel()
  },

  methods: {
    cancel() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async fetchCompositions() {
      try {
        this.cancel()
        this.cancelTokenSource = axios.CancelToken.source()
        if (this.mapIds?.length > 0) {
          this.compositions = [
            ...new Set(
              [
                ...(await Promise.all(
                  this.mapIds.map(async map_id => {
                    const compositions = await getScrimCompositions(
                      { map_id },
                      {
                        cancelToken: this.cancelTokenSource.token,
                      }
                    )
                    return compositions.map(composition => composition.join(','))
                  })
                )),
              ].flat()
            ),
          ].map(c => c.split(','))
        } else {
          this.compositions = await getScrimCompositions(
            {},
            {
              cancelToken: this.cancelTokenSource.token,
            }
          )
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        const error = (this.error = axios.extractErrorMessage(e))
        console.error(error, e)
        Sentry.captureException(e)
      }
    },

    onSubmit() {
      this.$emit('update', this.currentFilters)
    },

    onReset() {
      this.$emit('update', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters {
  &--item {
    &__title,
    &__composition {
      .filters--item--title + div {
        padding-bottom: 30px !important;
      }
    }
  }
}
</style>
